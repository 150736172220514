import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@mui/material';

import { Images } from '@/constants/Images';
import useBreakpoints from '@/hooks/utility/useBreakpoints';

import Card from '../utility/microcomponents/Card';

export const DefaultReleaseCycleCard = ({ isOne }: { isOne?: boolean }) => {
  const { t } = useTranslation();
  const { breakpointHit, breakpointHitCustom } = useBreakpoints(500);

  if (isOne) {
    return (
      <Card
        inner
        className={`p0 flex-grow ${breakpointHit ? 'min-h400' : 'max-h364'} pos-rel flex-basis placeholder-card-pink overflow-hidden`}
      >
        <div
          className={`d-flex pos-rel jc-space-evenly gap20 overflow-hidden ${breakpointHit ? 'flex-wrap' : 'pl20 pr20'}`}
        >
          <div
            className={`${breakpointHit ? 'text-center w100p mt-48 p10 order-2' : 'text-left mt-auto mb-auto pr20 max-w600'} ${breakpointHitCustom ? 'pb10' : breakpointHit ? 'pb20' : ''}`}
          >
            {!breakpointHit && <h2>{t('RELEASE-CYCLES.START-A-RELEASE-CYCLE')}</h2>}
            {breakpointHit && <h3 className="mt20">{t('RELEASE-CYCLES.START-A-RELEASE-CYCLE')}</h3>}
            <p className="mt4">{t('RELEASE-CYCLES.START-A-RELEASE-CYCLE-DESCRIPTION')}</p>

            <div
              className={`pos-rel ${breakpointHit ? 'ml-auto mr-auto' : ''}  ${breakpointHitCustom ? 'w100p' : 'w-fit'}`}
            >
              <Button
                className={`${breakpointHit ? 'mr0 mb0' : ''} btn-white ${breakpointHitCustom ? 'mt40' : 'mt20'}  ml0 mr0 z-1000 w100p-ml-down`}
              >
                {t('RELEASE-CYCLES.CREATE-A-NEW-RELEASE')}
              </Button>
            </div>
          </div>
          <img
            src={Images.placeholders.releaseCycle}
            alt="tiktok-ads"
            height={breakpointHit ? '220px' : '364px'}
            className={`${breakpointHit ? 'mt-10 min-w0' : 'object-fit-contain min-w50p-or-538'}`}
          />
        </div>
      </Card>
    );
  }

  return (
    <Card
      inner
      className="max-w950 p0 flex-grow max-h400 min-h400 pos-rel flex-basis placeholder-card-pink overflow-hidden flex-basis"
    >
      <div className={`d-flex pos-rel jc-center gap20 overflow-hidden flex-wrap`}>
        <div className={`text-center w100p mt-48 p10 order-2`}>
          {<h3 className={breakpointHit ? 'mt20 small' : 'mt0'}>{t('RELEASE-CYCLES.START-A-RELEASE-CYCLE')}</h3>}
          <p className="text-reduced-opacity mt4">{t('RELEASE-CYCLES.START-A-RELEASE-CYCLE-DESCRIPTION')}</p>
        </div>
        <div className="p10">
          <img
            src={Images.placeholders.releaseCycle}
            alt="tiktok-ads"
            height={breakpointHit ? '200px' : '250px'}
            className={`mt0 max-w100p object-fit-contain`}
          />
        </div>
      </div>
      <div className="pos-abs b0 w100p">
        <div className="p10 pos-rel pb20">
          <Button className={`m0 w100p-lg-down btn-white z-1000`}>{t('RELEASE-CYCLES.CREATE-A-NEW-RELEASE')}</Button>
        </div>
      </div>
    </Card>
  );
};
