import React from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '@mui/material';

const ReleaseCycleTaskCategoryStatus = ({
  // category,
  time,
  isModal,
  isOneTimeTask,
  isTimeSensitive,
}: {
  category?: number;
  time?: number;
  isModal?: boolean;
  isTimeSensitive?: boolean;
  isOneTimeTask?: boolean;
}) => {
  const { t } = useTranslation();

  if (isModal) {
    return (
      <div className="d-flex flex-wrap">
        <p className="text-faded small">
          <span className="pos-rel">
            <Icon className="fs-sm pos-abs t3">access_time</Icon>
            <span className="pl16">
              {time} {t('RELEASE-CYCLES.MIN')}
            </span>
          </span>
        </p>

        {isOneTimeTask && (
          <p className="is-one-time small">
            <span className="text-faded small">
              {'  '}|{'  '}
            </span>
            {t('RELEASE-CYCLES.ONE-TIME-TASK')}
          </p>
        )}
        {isTimeSensitive && (
          <p className="is-time-sensitive small">
            <span className="text-faded small">
              {'  '}|{'  '}
            </span>
            {t('RELEASE-CYCLES.TIME-SENSITIVE')}
          </p>
        )}
      </div>
    );
  }
  return (
    <div className="d-flex">
      <p className="text-faded small">
        <span className="pos-rel">
          <Icon className="fs-sm pos-abs t3">access_time</Icon>
          <span className="pl16">
            {time} {t('RELEASE-CYCLES.MIN')}
          </span>
        </span>
      </p>
    </div>
  );
};

export default ReleaseCycleTaskCategoryStatus;
