import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button, Icon } from '@mui/material';

import useOnboardingContext from '@/hooks/context/useOnboardingContext';
import { OnboardingSteps, PromoOptions } from '@/models/Enums';

import Card from '../utility/microcomponents/Card';

const ReleaseTypeChoice = () => {
  const { t } = useTranslation();
  const { watch, setValue } = useFormContext();
  const option = watch('releaseChoice');
  const [chosenOption, setChosenOption] = useState<PromoOptions>(option !== undefined ? option : PromoOptions.NEW);

  const { setOnboardingStep } = useOnboardingContext();

  useEffect(() => {
    setValue('releaseChoice', chosenOption);
  }, [chosenOption, setValue]);

  return (
    <div className="min-h100p">
      <h2 className="mt40 max-w1000 mr-auto ml-auto">{t('ONBOARDING.DO-YOU-HAVE-MUSIC-TO-PROMOTE')}</h2>

      <div className="mb150">
        <div
          className={`max-w400 ml-auto mr-auto mt20 ${chosenOption === PromoOptions.NEW ? 'to-do-card-border' : 'to-do-card-pre-border'}`}
        >
          <Card
            className="text-left d-flex cursor-pointer gap8 p16"
            onClick={() => {
              setChosenOption(PromoOptions.NEW);
            }}
          >
            <div>
              <h5>{t('RELEASE-CYCLES.PLAN-A-NEW-RELEASE')}</h5>
              <p className="text-faded small">{t('RELEASE-CYCLES.PLAN-A-NEW-RELEASE-DESCRIPTION')}</p>
            </div>
            <div className="ml-auto mb-auto">
              {chosenOption === PromoOptions.NEW ? (
                <div>
                  <Icon className="text-blue">check_circle</Icon>
                </div>
              ) : (
                <div>
                  <Icon className="material-symbols-outlined text-faded">circle</Icon>
                </div>
              )}
            </div>
          </Card>
        </div>
        <div
          className={`max-w400 ml-auto mr-auto mt20 ${chosenOption === PromoOptions.BOOST ? 'to-do-card-border' : 'to-do-card-pre-border'}`}
        >
          <Card
            className="text-left d-flex cursor-pointer gap8 p16"
            onClick={() => {
              setChosenOption(PromoOptions.BOOST);
            }}
          >
            <div>
              <h5>{t('RELEASE-CYCLES.PROMOTE-A-TRACK-THATS-ON-SPOTIFY')}</h5>
              <p className="text-faded small">{t('RELEASE-CYCLES.I-HAVE-A-SONG-ON-SPOTIFY')}</p>
            </div>
            <div className="ml-auto mb-auto">
              {chosenOption === PromoOptions.BOOST ? (
                <div>
                  <Icon className="text-blue">check_circle</Icon>
                </div>
              ) : (
                <div>
                  <Icon className="material-symbols-outlined text-faded">circle</Icon>
                </div>
              )}
            </div>
          </Card>
        </div>
      </div>
      <div className="onboarding-footer">
        <div className="d-flex jc-end mt40">
          <Button
            className="btn-white min-w130"
            disabled={chosenOption === undefined}
            onClick={() => {
              if (chosenOption === PromoOptions.NEW) {
                return setOnboardingStep(OnboardingSteps.RELEASE_DETAILS);
              }
              setOnboardingStep(OnboardingSteps.BOOST_RELEASE_TRACK_PAGE);
            }}
          >
            {t('COMMON.CONTINUE')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ReleaseTypeChoice;
